import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export const Music = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      className=" mx-auto max-w-7xl mt-48"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <h1 className="text-6xl font-hashiban text-white">{t("music_title")}</h1>
      <p className="text-3xl py-10">{t("music_subtitle")}</p>
      <iframe
        className="rounded-md"
        src="https://open.spotify.com/embed/album/1zhm260xqksGv5iw73wgWH?utm_source=generator&theme=0"
        width="100%"
        height="200px"
        allowfullscreen=""
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </motion.div>
  );
};
