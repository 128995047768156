import "./styles/App.css";
import Header from "./components/Header/header";
import ImageTop from "./components/ImageTop/image";
import { Text, TextWitMerchant } from "./components/IntroductoryText/Text";
import { useState } from "react";
import NavBar from "./components/BuyTab/buy-tab";
import ImageBottom from "./components/ImageBot/Image_bottom";
import BasicInfo from "./components/BasicInfoDisplay/BasicInfo";
import Footer from "./components/Footer/Footer";
import Spinner from "./components/Spinner/Spinner";
import Contact from "./components/Contact/Contact";
import { useTranslation } from "react-i18next";
import Obtain from "./components/Obtain/Obtain";
import { VideoPlayer } from "./components/Video/Video";
import { Music } from "./components/Music/Music";

function Home() {
  const [loaded, setLoaded] = useState(false);
  const [buyTab, setBuyTab] = useState(false);
  const [contactEnabled, setContactEnabled] = useState(false);
  const { t } = useTranslation();
  const Playit = () => {
    var audio = new Audio("theme.mp3");
    audio.play();
  };

  return (
    <div className="main">
      {contactEnabled && <Contact setContactEnabled={setContactEnabled} />}
      {/* {crowdEnabled && (
        <Crowd setCrowdEnabled={setCrowdEnabled} setBuyTab={setBuyTab} />
      )} */}
      <NavBar setBuyTab={setBuyTab} buyTab={buyTab} />
      <Header setBuyTab={setBuyTab} buyTab={buyTab} />
      <ImageTop setLoaded={setLoaded} />
      {!loaded && <Spinner />}
      {loaded && (
        <>
          <Obtain openBuyTab={() => setBuyTab(true)} />
          <ImageBottom />
          <TextWitMerchant text={t("description")} />
          <BasicInfo />
          <div className="h-48" />
          <VideoPlayer />
          <Music />
          <Footer setContactEnabled={setContactEnabled} />
        </>
      )}
    </div>
  );
}

export default Home;
