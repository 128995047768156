import React from "react";
import "./footer.css";
import { IoLogoInstagram } from "react-icons/io";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IFooterProps {
  setContactEnabled: React.Dispatch<React.SetStateAction<any>>;
}

function Footer({ setContactEnabled }: IFooterProps) {
  const { t } = useTranslation();
  return (
    <div>
      <nav className="flex gap-4 justify-center py-4 lg:justify-end px-4 mt-48">
        {/* <a className="text-xl">{t("about_us")}</a> */}
        {/* <Link to="/instrucciones" className="text-xl">
          {t("instructions")}
        </Link> */}
        <a className="text-xl" onClick={() => setContactEnabled(true)}>
          {t("contact")}
        </a>
        <a
          href="https://www.instagram.com/hashiban_es/"
          className="std-button ig-icon"
        >
          <IoLogoInstagram />
        </a>
      </nav>
    </div>
  );
}

export default Footer;
