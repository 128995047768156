import { VideoPlayer } from "../Video/Video";

const HashibanInstructions = () => {
  return (
    <div className="w-full bg-black">
      <div className="max-w-6xl p-4 mx-auto">
        <video controls className="w-full rounded-md py-20">
          <source src="/video_es.mp4" type="video/mp4" />
        </video>
        <h1 className="text-3xl font-bold mb-4">
          Instrucciones del Juego: Subasta de Hashiban
        </h1>

        <BigTitle>Objetivo del juego</BigTitle>
        <p className="mb-4">
          Revelar tus 3 mercaderes, obteniendo 3 objetos de cada uno para poder
          sacarlos. Los objetos se consiguen a través de subastas que se
          realizan en cada turno.
        </p>

        <BigTitle>Componentes del Juego</BigTitle>
        <ul className="list-disc list-inside mb-4">
          <li>
            <strong>Mercaderes:</strong> 16 en total, cada uno busca un tipo
            específico de objeto.
          </li>
          <li>
            <strong>Billetes:</strong>
            <ul className="list-disc list-inside">
              <li>19 de mil</li>
              <li>15 de dos mil</li>
              <li>10 de cinco mil</li>
              <li>1 token dorado (valor 10 mil)</li>
            </ul>
          </li>
          <li>
            <strong>Acciones:</strong> 5 acciones disponibles.
          </li>
          <li>
            <strong>Objetos:</strong> 50 objetos variados.
          </li>
        </ul>

        <BigTitle>Preparación del Juego</BigTitle>
        <ol className="list-decimal list-inside mb-4">
          <li>
            <strong>Reparto de Cartas:</strong>
            <p>Cada jugador recibe aleatoriamente:</p>
            <ul className="list-disc list-inside">
              <li>3 mercaderes</li>
              <li>5 billetes</li>
              <li>1 objeto</li>
            </ul>
            <li>
              Los jugadores deben mantener sus cartas ocultas, especialmente los
              mercaderes.
            </li>
            <p>
              Los mercaderes que no reciba ningún jugador deben permanecer
              ocultos.
            </p>
            <p>
              Si un jugador recibe una acción sobre el mercader como primer
              objeto, deberá cambiarlo por otro objeto y barajar la acción en la
              pila de objetos.
            </p>
          </li>
          <li>
            <strong>Mesa de Juego:</strong>
            <p>
              Colocar dos pilas de cartas (boca abajo): una de objetos y otra de
              billetes. Colocar el mercader público al lado.
            </p>
            <p>
              El mercader pùblico se selecciona aleatoriamente de entre los
              mercaderes que no han sido distribuidos. Luego, los mercaderes
              restantes se escoderán en la caja.
            </p>
          </li>
        </ol>

        <BigTitle>Desarrollo del Juego</BigTitle>
        <li>El jugador más anciano inicia el juego.</li>
        <li>
          Al comienzo de su turno, el jugador toma un billete aleatorio de la
          pila de billetes.
        </li>
        <li>
          Luego puede elegir una de las siguientes acciones:
          <ol className="list-disc">
            <MediumTitle>Subastar Objeto Aleatorio</MediumTitle>
            <li>
              El jugador puede levantar un objeto de la pila de objetos y hacer
              la primera puja. Luego, los jugadores, en orden, pueden aumentar
              la puja anterior o pasar. La subasta termina cuando nadie está
              dispuesto a aumentar la puja. Entonces el máximo pujante
              depositará la cantidad pujada en el fondo de la pila de billetes,
              y obtendrá el objeto.
              <it>
                Si ningún jugador puja, el objeto irá al fondo de la pila de
                objetos, y el jugador cuyo turno se estaba jugando cobrará un
                billete de la pila de billetes.
              </it>
            </li>
            <li>
              <MediumTitle>Sacar Lote a Subasta</MediumTitle>
              Un jugador puede sacar a subasta un lote de objetos de su mano y
              establecer un precio inicial. Si no hay pujas, el lote se coloca
              en el fondo de la pila de objetos y el jugador cobra un billete
              aleatorio por cada objeto en el lote.
            </li>
            <li>
              <MediumTitle>Bonoloto</MediumTitle>
              Un jugador puede usar su turno para jugar al abonoloto, usando 5
              billetes de mil. Retira 5 billetes de la pila de objetos y obtiene
              las parejas que pueda formar, y el token dorado, en caso de que
              este se encuentre entre los billetes retirados.
            </li>
            <li>
              <MediumTitle>Revelar Mercader</MediumTitle>
              <p>
                Un jugador puede revelar un mercader de su mano mostrando 3
                objetos de ese mercader. El mercader se coloca en el fondo de la
                pila de objetos y el jugador comenzará a cobrar billetes un
                billete de la pila de billetes cada vez que se subasten objetos
                de su categoría. Esta acción consume el turno del jugador.
              </p>
              <p>
                Excepcionalmente, si el mismo jugador que tiene revelado un
                mercader saca a subasta un objeto de la misma categoría, no
                cobrará por el.
              </p>
            </li>
          </ol>
          <BigTitle>Objetos Especiales</BigTitle>
          <MediumTitle>Acciones sobre el Mercader</MediumTitle>
          Cuando una acción aparece en la pila de objetos, los jugadores pujan
          por ella. El jugador que obtiene la acción puede colocarla en su
          campo, junto con los mercaderes que haya revelado. Desde ese momento,
          el jugador cobra un billete cada vez que aparezca un objeto de la
          categoría del mercader público.
          <MediumTitle>Uso de Armas</MediumTitle>
          Algunos objetos son armas, denotado por la categoría con el icono de
          un arco. Pueden usarse para eliminar a un jugador de una ronda de
          subasta en cualquier momento. Si el jugador había pujado, puede
          recuperar su dinero. Un jugador muerto no puede intercambiar dinero ni
          objetos con otros jugadores, ni matar a otro jugador.
        </li>

        <BigTitle>Estrategia</BigTitle>
        <p className="mb-4">
          Actúa estratégicamente y aprende a ser un "trasgo" para ganar.
        </p>

        <footer className="mt-8 text-center text-gray-600">
          <p>¡Disfruta de cada partida de Subasta de Hashiban!</p>
        </footer>
      </div>
    </div>
  );
};

const Paragraph = ({ children }) => {
  return <p className="mb-4">{children}</p>;
};

const BigTitle = ({ children }) => {
  return <h1 className="text-3xl font-bold my-4">{children}</h1>;
};

const MediumTitle = ({ children }) => {
  return <h2 className="text-2xl font-semibold mt-6">{children}</h2>;
};

const ListItem = ({ children }) => {
  return <li>{children}</li>;
};

export default HashibanInstructions;
