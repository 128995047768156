import "./basic_info.css";
import DescriptionCarousel from "../CardDisplay/description-carousel";

function BasicInfo() {
  return (
    <div className="basic-info-display_carousel">
      <DescriptionCarousel />
    </div>
  );
}

export default BasicInfo;
